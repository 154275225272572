var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-autocomplete", {
        staticClass: "with-full-border",
        attrs: {
          items: _vm.users,
          "item-text": "name",
          "item-value": "uuid",
          "hide-details": "",
          placeholder: "Juridische Zaken",
          "search-input": _vm.searchInput,
          clearable: "",
          multiple: ""
        },
        on: {
          "update:searchInput": function($event) {
            _vm.searchInput = $event
          },
          "update:search-input": function($event) {
            _vm.searchInput = $event
          },
          keyup: this.searchDebounce,
          change: _vm.handleChange
        },
        scopedSlots: _vm._u(
          [
            _vm.canSelectAll
              ? {
                  key: "prepend-item",
                  fn: function() {
                    return [
                      _c(
                        "v-list-tile",
                        [
                          _c("v-checkbox", {
                            attrs: { color: "primary", "hide-details": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c("span", { staticClass: "pl-4" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.selectAll
                                                ? "(deselecteer alles)"
                                                : "(selecteer alles)"
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              953812867
                            ),
                            model: {
                              value: _vm.selectAll,
                              callback: function($$v) {
                                _vm.selectAll = $$v
                              },
                              expression: "selectAll"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              : null,
            {
              key: "selection",
              fn: function(ref) {
                var item = ref.item
                var index = ref.index
                return [
                  index === 0
                    ? [
                        _c("span", [_vm._v("Juridische Zaken ")]),
                        _c("span", { staticClass: "grey--text caption" }, [
                          _vm._v("(+" + _vm._s(_vm.selectedUsers.length) + ")")
                        ])
                      ]
                    : _vm._e()
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.selectedUsers,
          callback: function($$v) {
            _vm.selectedUsers = $$v
          },
          expression: "selectedUsers"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }