import { Component, Prop, Watch } from 'vue-property-decorator';
import { debounce, isEmpty, cloneDeep, isArray } from 'lodash';
import { DateTime } from 'luxon';
import { AxiosError, AxiosResponse } from 'axios';
import { styles } from '@/support/Style';
import ErrorHandler from '@/support/ErrorHandler';
import { ReportStatusMap, getStatusLabel, sortedExcludePlanningStatuses } from '@/support/ReportStatus';
import { Options } from '@/components/mi-dialog/MiDialog';
import { User, userRoles } from '@/models/User';
import { SSO } from '@/models/SSO';
import { Report, WorkFlows } from '@/models/Report';
import { ReportOrganizations } from '@/support/ReportOrganizations';
import { ReportTypes } from '@/support/ReportTypes';
import { ReportTags } from '@/support/ReportTags';
import { Address } from '@/models/Address';
import { ReportFilters } from '@/models/UserFilter';
import UserFilter from '@/components/user-filter/UserFilter';
import ReportPlanningStatusFilter from '@/components/filters/report-planning-status-filter/ReportPlanningStatusFilter.vue';
import ReportObjectionTypeFilter from '@/components/filters/report-objection-type-filter/ReportObjectionTypeFilter.vue';
import ReportJzFilter from '@/components/filters/report-jz-filter/ReportJzFilter.vue';
import ReportLevelFilter from '@/components/filters/report-level-filter/ReportLevelFilter.vue';
import ReportDatatable from '@/components/ReportDatatable/ReportDatatable';
import { Setting } from '@/models/Setting';
import CreateReportDialog from '@/components/dialog/create-report-dialog/CreateReportDialog.vue';
import { MoreInfo } from '@/components/dialog/more-info-dialog/MoreInfoDialog';
import { LegendaStatusDotInfo } from '@/support/Info';
import AbstractMoreInfoDialog from '@/components/dialog/more-info-dialog/AbstractMoreInfoDialog';
import { fetchUserPreferences } from '@/support/UserPreferencesHelper';
import { UserPreference } from '@/models/UserPreference';
import { onHoldReasonLabels, OnHoldReasonUuidEnum } from '@/models/OnHoldReason';
import { ProductionPeriodEnum } from '@/items/ProductionPeriod';
import { ProductionStatusEnum } from '@/items/ProductionStatus';
import { Route } from 'vue-router';
import { WorkflowEnum } from '@/components/filters/work-flow-filter/WorkFlowFilter';

@Component<Reports>({
  components: {
    ReportJzFilter,
    ReportLevelFilter,
    ReportPlanningStatusFilter,
    ReportObjectionTypeFilter,
    CreateReportDialog,
  },
})
export default class Reports extends AbstractMoreInfoDialog {
  @Prop({ default: undefined })
  protected hideTitle!: boolean | string;

  @Prop({ default: true })
  protected useUserFilters!: boolean;

  public $pageTitle = 'Dossiers';

  protected filters: object = {};

  protected moreInfo: MoreInfo[] = LegendaStatusDotInfo;

  protected tableSearchInput = '';

  protected reports: Report[] = [];

  protected reportStatusMap: ReportStatusMap[] = sortedExcludePlanningStatuses();

  protected expertFirstPersonSearchInput = '';

  protected secondExpertSearchInput = '';

  protected reportHandlerSearchInput = '';

  protected reportCreated = false;

  protected selectedFilterName = 'Mijn filters';

  protected isCreatingFilter = false;

  protected filterDefault = false;

  protected selectedFilter: ReportFilters = {};

  protected isLoadingDatatable = true;

  protected isLoading = false;

  protected isCreatingReport = false;

  protected isFiltersMenuOpen = false;

  protected isShowingFilters = false;

  protected caseNumber = '';

  protected reportType = '';

  protected section = '';

  protected report: Report | null = null;

  protected selectAllStatus = false;

  protected filtersHeight = 0;

  public searchFirstPersonDebounce: () => void = debounce(() => this.getExpertsFirstPerson(), 400);

  protected firstPersonExperts: User[] = [];

  protected userRoles = userRoles;

  protected deadlineIcons: any = {
    approved: require('@/assets/images/icons/request-accepted.svg'),
    pending: require('@/assets/images/icons/request-pending.svg'),
    rejected: require('@/assets/images/icons/request-rejected.svg'),
  }

  protected hideDeadlineColumn: string[] = ['created', 'planning', 'downloaded', 'aborted', 'objection_closed', 'smr_closed', 'smr_created', 'objected_created'];

  protected tableFilters: ReportFilters = {};

  protected tableFilterMap: {[key: string]: any} = {
    opname: {
      statuses: [
        'opname_in_progress',
      ],
      overdue: true,
    },
    external: {
      ohw: 'external',
      is_test: false,
    },
    planned: {
      ohw: 'planned',
      is_test: false,
    },
    replanned: {
      ohw: 'replanned',
      is_test: false,
    },
    todo: {
      ohw: 'todo',
      is_test: false,
    },
    total: {
      ohw: '',
      is_test: false,
    },
    external_special: {
      ohw: 'external',
      is_special: true,
    },
    planned_special: {
      ohw: 'planned',
      is_special: true,
    },
    replanned_special: {
      ohw: 'replanned',
      is_special: true,
    },
    todo_special: {
      ohw: 'todo',
      is_special: true,
    },
    total_special: {
      is_special: true,
    },
    kpi: {
      overdue_kpi: true,
    },
    zelfopname: {
      opname_variants: ['zelf_opname'],
    },
    bac: {
      types: [
        ReportTypes.BEZWAAR_2021,
        ReportTypes.ADVIESRAPPORT_BEZWAAR_HOORZITTING,
        ReportTypes.ADVIESRAPPORT_BEZWAAR_ADDENDUM,
        ReportTypes.ADVIESRAPPORT_BEZWAAR,
        ReportTypes.BEZWAAR_BAC_2,
        ReportTypes.BEZWAAR_BAC_2_CALCULATIE,
      ],
    },
    ambtelijk: {
      types: [
        ReportTypes.ADVIESRAPPORT_BEZWAAR_AMBTELIJK,
        ReportTypes.BEZWAAR_AMBTELIJK_2,
        ReportTypes.BEZWAAR_AMBTELIJK_2_CALCULATIE,
      ],
    },
    lld_planning_regulier: {
      tags: [ReportTags.LLD],
      statuses: ['planning'],
      planned_class: 'regulier',
    },
    lld_planning_opnemersvariant: {
      tags: [ReportTags.LLD],
      statuses: ['planning'],
      planned_class: 'opnemersvariant',
    },
    lld_planning_specials: {
      tags: [ReportTags.LLD],
      statuses: ['planning'],
      planned_class: 'specials',
    },
    lld_back_to_planning_vervolgopname: {
      tags: [ReportTags.LLD],
      statuses: ['back_to_planning'],
      planning_class_status: 'vervolgopname',
    },
    lld_planning_heropname: {
      tags: [ReportTags.LLD],
      statuses: ['planning'],
      planned_class: 'heropname',
    },
    lld_planning_opname_op_afstand: {
      tags: [ReportTags.LLD],
      statuses: ['planning'],
      planned_class: 'opname_op_afstand',
    },
    lld_back_to_planning_nader_onderzoek: {
      tags: [ReportTags.LLD],
      statuses: ['back_to_planning'],
      planning_class_status: 'nader_onderzoek',
    },
    lld_back_to_planning_aanvullend_onderzoek: {
      tags: [ReportTags.LLD],
      statuses: ['back_to_planning'],
      planning_class_status: 'aanvullend_onderzoek',
    },
    onderzoeken_planning_heropname: {
      statuses: ['planning'],
      planned_class: 'heropname',
    },
    onderzoeken_back_to_planning_vervolgopname: {
      statuses: ['back_to_planning'],
      planning_class_status: 'vervolgopname',
    },
    onderzoeken_back_to_planning_nader_onderzoek: {
      statuses: ['back_to_planning'],
      planning_class_status: 'nader_onderzoek',
    },
    onderzoeken_back_to_planning_aanvullend_onderzoek: {
      statuses: ['back_to_planning'],
      planning_class_status: 'aanvullend_onderzoek',
    },
    planning_opname: {
      organizations: [
        ReportOrganizations.EXPERT,
      ],
    },
    ves_interested: {
      tags: [ReportTags.VES_SUITABLE],
    },
    ves_workflow: {
      workflows: [WorkflowEnum.VES],
    },
    'rapportage-production': ((route) => this.getTableFilterMapRapportageProductionFromRoute(route))(this.$route),
  };

  protected getTableFilterMapRapportageProductionFromRoute(route: Route): any {
    let managementInfoClasses: string[] = [];

    switch (route.query['production-status'] as ProductionStatusEnum) {
      case ProductionStatusEnum.STANDAARD:
        managementInfoClasses = ['standaard', 'standaard_groot'];
        break;

      case ProductionStatusEnum.ZIENSWIJZE:
        managementInfoClasses = ['nader_advies'];
        break;

      case ProductionStatusEnum.SPECIALS:
        managementInfoClasses = ['special'];
        break;

      case ProductionStatusEnum.OBJECTION:
        managementInfoClasses = ['bezwaar'];
        break;

      case ProductionStatusEnum.WOCO_V1:
        managementInfoClasses = ['wocov1'];
        break;

      case ProductionStatusEnum.WOCO_V2:
        managementInfoClasses = ['wocov2'];
        break;

      case ProductionStatusEnum.VES:
        managementInfoClasses = ['ves'];
        break;

      default:
        managementInfoClasses = [];
        break;
    }

    const approvedAtFrom = route.query['production-from']
      ? DateTime.fromFormat((route.query['production-from'] as string), 'yyyy-LL-dd').toFormat('yyyy-LL-dd')
      : DateTime.now().startOf(ProductionPeriodEnum.WEEK).toFormat('yyyy-LL-dd');

    const approvedAtTill = route.query['production-till']
      ? DateTime.fromFormat((route.query['production-till'] as string), 'yyyy-LL-dd').toFormat('yyyy-LL-dd')
      : DateTime.now().endOf(ProductionPeriodEnum.WEEK).toFormat('yyyy-LL-dd');

    return {
      management_info_classes: managementInfoClasses,
      approved_at: [
        approvedAtFrom,
        approvedAtTill,
      ],
    };
  }

  protected ohwFilters = [
    {
      name: 'Externe Partij',
      value: 'external',
    },
    {
      name: 'Toekomstig Gepland',
      value: 'planned',
    },
    {
      name: 'Vervolgafspraak',
      value: 'replanned',
    },
    {
      name: 'Onderhanden werk',
      value: 'todo',
    },
  ];

  protected selectedOhwFilter = '';

  private isLoadingSettings = true;

  private settings: Setting[] = [];

  private showArchived = false;

  protected userPreferences: UserPreference[] | null = null;

  protected isLoadingUserPreferences = true;

  protected get queryStatus() {
    if (this.$route.query.planning_status) {
      return this.$route.query.planning_status;
    }

    return this.$route.query.status;
  }

  protected get queryHeaders(): string {
    return this.$route.query.headers as string;
  }

  protected get isSimpleUser() {
    return this.$store.state.Auth && (this.$store.state.Auth.type === 'simple_plus' || this.$store.state.Auth.type === 'simple');
  }

  public logout() {
    this.$store.dispatch('userDeauthenticated');
    this.$router.push('/login');
  }

  public mounted() {
    this.getSettings();
    this.fetchUserPreferences();
    if (this.isSimpleUser) {
      this.initializeSimpleUser();
    } else {
      this.initialize();
    }
  }

  protected async initialize() {
    this.emitBreadcrumb();

    this.isLoadingDatatable = true;

    if (this.showExpertsFirstPersonFilter) {
      await this.getExpertsFirstPerson();
    }

    this.isLoadingDatatable = false;

    this.fetchUrlFilters();
  }

  protected async fetchUserPreferences(): Promise<void> {
    this.isLoadingUserPreferences = true;
    this.userPreferences = await fetchUserPreferences();
    this.isLoadingUserPreferences = false;
  }

  protected fetchUrlFilters() {
    if (! this.useUserFilters) {
      this.resetFilters();
    }

    const filterKey = this.$route.query.filter;

    this.section = this.$route.query.section as string;
    if (this.section && this.section.length > 0) {
      this.tableFilters.section = this.section;
    }

    // Check all the route parameters, and create the filter
    for (const key of ['organizations', 'exclude_types', 'opname_variants', 'statuses', 'types', 'on_hold_reasons', 'is_special']) {
      if (this.$route.query[key]) {

        let value = `${this.$route.query[key]}`
        try {
          // If the route is an array, we want to parse it first. Because the route params are always a string.
          // If it fails, it's because the param is already a string and not valid json. That is fine.
          // Ex: &organizations=["13371337-1337-1337-1337-133713371337"] will become an array of strings because of the json parse
          // Ex &organizations=13371337-1337-1337-1337-133713371337 will also work because of the catch
          value = JSON.parse(`${this.$route.query[key]}`)
        } catch (e) {}

        if (Array.isArray(value)) {
          this.tableFilters[key] = value as string[];
        } else {
          this.tableFilters[key] = [value] as string[];
        }
      }
    }

    if (this.$route.query.workflow) {
      this.tableFilters['workflow'] = this.$route.query.workflow;
    }

    this.selectedOhwFilter = filterKey as string;
    const tableFilterMap = cloneDeep(this.tableFilterMap[filterKey as string]);

    if (filterKey === 'rapportage-production') {
      const approvedAtFrom = this.$route.query['production-from']
        ? DateTime.fromFormat((this.$route.query['production-from'] as string), 'yyyy-LL-dd').toFormat('yyyy-LL-dd')
        : DateTime.now().startOf(ProductionPeriodEnum.WEEK).toFormat('yyyy-LL-dd');

      const approvedAtTill = this.$route.query['production-till']
        ? DateTime.fromFormat((this.$route.query['production-till'] as string), 'yyyy-LL-dd').toFormat('yyyy-LL-dd')
        : DateTime.now().endOf(ProductionPeriodEnum.WEEK).toFormat('yyyy-LL-dd');

      const approved_at = [
        approvedAtFrom,
        approvedAtTill,
      ];

      this.tableFilters = { ...this.tableFilters, ...tableFilterMap, ...{ approved_at } };
      return;
    }

    this.tableFilters = { ...this.tableFilters, ...tableFilterMap };
  }

  protected async getExpertsFirstPerson(): Promise<void> {
    this.firstPersonExperts = await new User()
      .filter('search', this.expertFirstPersonSearchInput)
      .limit(500)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected initializeSimpleUser() {
    this.isLoading = true;
    this.fetchReports();
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Mijn schades' },
        ],
      });
  }

  protected fetchReports() {
    new Report()
      .all()
      .then((reports: Report[]) => {
        this.reports = reports;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected emitBreadcrumb() {
    let currentStatus = this.queryStatus;
    if (! currentStatus || ! currentStatus.length) {
      currentStatus = 'Alle rapporten';
    }

    if (this.user.hasRole(['simple', 'simple_plus'])) {
      this.$root.$emit('breadcrumbUpdated',
        {
          crumb: [
            { name: 'Mijn schades' },
          ],
        });
    } else {
      const breadCrumb: any = [
        {
          name: 'Alle rapporten',
          link: '/reports',
        },
      ];
      if (currentStatus !== 'Alle rapporten') {
        breadCrumb.push({
          name: getStatusLabel((currentStatus as string)),
        });
      }

      this.$root.$emit('breadcrumbUpdated',
        {
          crumb: breadCrumb,
        });
    }
  }

  get user(): User {
    return this.$store.state.Auth;
  }

  private get dataTableFilters() {
    return this.tableFilters;
  }

  protected hasQueryStatus(status: string | string[]) {
    if (! this.queryStatus || this.queryStatus === undefined) {
      return false;
    }

    if (! Array.isArray(status)) {
      status = [status];
    }

    let hasStatus = false;
    if (status.includes(this.queryStatus as string)) {
      hasStatus = true;
    }

    return hasStatus;
  }

  protected get tableName(): string {
    // WERKBAKKEN: Planning On-hold
    if (this.$route.query.status === 'planning_on_hold' && this.$route.query.on_hold_reasons) {
      const onHoldReasonUuids: OnHoldReasonUuidEnum[] = JSON.parse(`${this.$route.query.on_hold_reasons}`);

      if (isArray(onHoldReasonUuids) && onHoldReasonUuids.length) {
        const onHoldReasons = onHoldReasonUuids.map((onHoldReasonUuid) => onHoldReasonLabels[onHoldReasonUuid] || '');

        return `${getStatusLabel('planning_on_hold')}: ${onHoldReasons.join(' | ')}`;
      }
    }

    if (this.$route.query.planning_workflow) {
      const foundWorkflow = WorkFlows.find((workflow) => this.$route.query.planning_workflow === workflow.value);
      return foundWorkflow ? foundWorkflow.name || 'Alle rapporten' : 'Alle rapporten';
    }

    let currentStatus = this.queryStatus;
    if (! currentStatus) {
      currentStatus = 'Alle rapporten';
    }

    return currentStatus === 'Alle rapporten' ? currentStatus : getStatusLabel((currentStatus as string));
  }

  protected get expertPlanningInclude(): string {
    const planningStatuses = [
      'vervolgopname',
      'aanvullend_onderzoek',
      'nader_onderzoek',
      'heropname',
    ];

    if (planningStatuses.some((status: string) => status === this.queryStatus)) {
      return 'planning_specials';
    }

    return '';
  }

  protected get isJzFilter() {
    if (this.$store.state.Auth.hasRole(['manager', 'admin'])) {
      return {};
    }

    if (this.queryStatus === 'specials_in_review') {
      return {
        jz_users: [this.$store.state.Auth.uuid],
      };
    }

    return {};
  }

  get dialogOptions(): Options {
    return {
      title: 'Rapport verwijderen',
      text: "Weet u zeker dat u dit rapport wilt verwijderen? Alle data (en foto's) worden dan verwijderd.",
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          color: 'warning',
          action: () => {
            if (! this.report) {
              return;
            }

            this.report
              .delete()
              .then((response: AxiosResponse) => {
                if (response && this.$refs.reportDatatable) {
                  (this.$refs.reportDatatable as ReportDatatable).refresh();
                }
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.report = null;
          },
        },
      },
    };
  }

  protected parseNameList(array: any) {
    let names = '';

    if (! array) {
      return names;
    }

    array.forEach((object: any, index: number) => {
      index == 0 ? names += `${object.name}` : names += `, ${object.name}`;
    });

    return names;
  }

  protected parseDeadline(deadline_at: string, report: Report) {
    if (deadline_at === '0000-00-00 00:00:00') {
      return '';
    }

    const deadlineDate = DateTime.fromFormat(deadline_at, 'yyyy-LL-dd HH:mm:ss');
    const currentDate = DateTime.local();
    const difference = deadlineDate.diff(currentDate, 'days').toObject().days;
    const deadlineNumber = Math.floor((difference as any));

    let deadlineIcon = '';
    let deadlineNumberClass = '';
    let deadlineNumberType = '';

    if (report && report.last_late_request_status) {
      deadlineIcon = `<span><img class="deadlineIcon" src="${this.deadlineIcons[report.last_late_request_status]}"></span>`;
    }

    if (deadlineNumber < 0) {
      deadlineNumberClass = 'negativeNumber';
    } else if (deadlineNumber >= 0 && deadlineNumber < 5) {
      deadlineNumberClass = 'warningNumber';
      deadlineNumberType = '+';
    } else {
      deadlineNumberClass = 'positiveNumber';
      deadlineNumberType = '+';
    }

    return `<span class="deadlineNumber ${deadlineNumberClass}">${deadlineNumberType}${deadlineNumber}</span> ${deadlineIcon}`;
  }

  protected parseAddress(address: Address) {
    if (! address) {
      return '';
    }

    if (! address.street && ! address.number && ! address.number_add && ! address.postcode && ! address.city) {
      return '';
    }

    return `${address.street} ${address.number} ${address.number_add} <br>
            ${address.postcode} ${address.city}`;
  }

  protected createdNewReport(report: Report) {
    this.$router.push(`/reports/${report.uuid}`);
  }

  protected getColor(key: string): string {
    return styles[key];
  }

  protected getSSOLink(caseNumber: string) {
    new SSO()
      .create({ case_number: caseNumber })
      .then((sso: SSO) => {
        if (sso && sso.app_link) {
          window.location.href = sso.app_link;
        }
      })
      .catch(() => {});
  }

  protected get showExpertFilter() {
    return ! this.$store.state.isServiceOrganization && ! this.$store.state.Auth.hasRole('expert');
  }

  protected get workPreparation() {
    if (! this.$store.state.Auth) {
      return false;
    }

    const hasCorrectLevel = this.$store.state.Auth.hasRole(this.userRoles.WVRoles);
    const isWorkPreperation = this.$route.fullPath.includes('?status=created') || this.$route.fullPath.includes('?status=smr_created');
    if (hasCorrectLevel && isWorkPreperation) {
      return true;
    }

    const hasCorrectLevelAppealsCommittee = this.$store.state.Auth.hasRole(this.userRoles.ObjectionRoles);
    const isWorkPreperationAppealsCommittee = this.$route.fullPath.includes('?status=objection_created');

    return hasCorrectLevelAppealsCommittee && isWorkPreperationAppealsCommittee;
  }

  private async getSettings(): Promise<void> {
    this.isLoadingSettings = true;
    const settings: Setting[] = await new Setting()
      .all()
      .catch((error: AxiosError): Setting[] => {
        ErrorHandler.network(error);
        return [];
      });
    this.settings = settings;
    this.isLoadingSettings = false;
  }

  private get hasExpertArchiveVisibility(): boolean {
    if (this.$store.state.isServiceOrganization || ! this.$store.state.Auth.hasRole(['expert'])) { return false; }
    const result: Setting | undefined = this.settings.find((setting: Setting): boolean => setting.key === 'expert_archive_visibility');
    return !! result && !! result.value;
  }

  protected get showExpertsFirstPersonFilter(): boolean {
    return ! this.$store.state.isServiceOrganization && this.$store.state.Auth.hasRole(userRoles.ManagerRoles);
  }

  // USER FILTERS
  // Not on beforeDestroy() but on updated()
  // - Because when reports.vue is used in router-view component, beforeDestroy is called after the $route params have changed
  // - updated is called on the correct moments in the lifecycle
  // - the call to this lifecycle hook occurs quite often, but this exception is acceptable untill full refactor of filters, and because there's no api call or heavy calculation bound to this
  protected updated() {
    if (this.isLoading) { return; }

    this.$store.dispatch('changeLastUsedReportFilter', { ...this.dataTableFilters, ...{ user_filter_type: this.queryStatus || 'reports' } });
  }

  protected handleApplyFilter(selectedUserFilters: ReportFilters) {
    this.$set(this, 'tableFilters', selectedUserFilters);
    this.tableFilters.section = this.section || '';
    this.tableFilters.planning_workflow = this.$route.query.planning_workflow || '';
    this.tableSearchInput = selectedUserFilters.search ? cloneDeep(selectedUserFilters.search) : '';
  }

  public resetFilters() {
    (this.$refs.userFilter as UserFilter).resetFilterName();
    this.tableFilters = {
      section: this.section || '',
    };
    this.tableSearchInput = '';
    if (this.queryStatus) {
      this.tableFilters[this.$route.query.planning_status ? 'planning_status' : 'status'] = this.queryStatus as string;
    } else {
      this.tableFilters[this.$route.query.planning_status ? 'planning_status' : 'status'] = '';
    }
  }

  protected get filterPayload() {
    if (this.$refs.reportDatatable) {
      this.tableFilters.search = (this.$refs.reportDatatable as ReportDatatable).searchInputValue;
    }

    if (this.$route.query.planning_workflow) {
      this.tableFilters.planning_workflow = this.$route.query.planning_workflow;
    }

    // When navigating from Raports overview to Raports overview (e.g. werkbakken),
    // this.$route.query appears not to be set directly when we reinitialze,
    // therefore we read this.$route.query again at this point.
    if (this.$route.query.on_hold_reasons) {
      this.tableFilters.on_hold_reasons = JSON.parse(`${this.$route.query.on_hold_reasons}`);
    }

    if (this.queryStatus) {
      if (this.$route.query.planning_status) {
        this.tableFilters.planning_status = this.queryStatus as string;
        this.tableFilters.status = '';
      } else {
        this.tableFilters.status = this.queryStatus as string;
        this.tableFilters.planning_status = '';
      }
    } else {
      this.tableFilters.status = '';
      this.tableFilters.planning_status = '';
    }

    return this.tableFilters;
  }

  protected get hasFilters(): boolean {
    let hasFilters = false;

    Object.keys(this.tableFilters).forEach((tableFilterKey: string) => {
      if (tableFilterKey) {
        if ((this.tableFilters as any)[tableFilterKey] === true) {
          hasFilters = true;
        } else if (! isEmpty((this.tableFilters as any)[tableFilterKey]) && tableFilterKey !== 'status' && tableFilterKey !== 'planning_status' && tableFilterKey !== 'planning_workflow' && tableFilterKey !== 'on_hold_reasons') {
          hasFilters = true;
        }
      }
    });

    return hasFilters;
  }

  protected resetFilterName() {
    if ((this.$refs.userFilter)) {
      (this.$refs.userFilter as UserFilter).resetFilterName();
    }
  }

  protected showFilters() {
    if (! this.isShowingFilters) {
      this.isShowingFilters = true;
      const innerFilters = document.getElementsByClassName('inner-filters');
      this.filtersHeight = innerFilters[0].clientHeight;
    } else {
      this.isShowingFilters = false;
      this.filtersHeight = 0;
    }
  }

  protected get filtersLength(): number {
    let filtersLength = 0;
    Object.keys(this.tableFilters).forEach((tableFilterKey: string) => {
      if (tableFilterKey) {
        if ((this.tableFilters as any)[tableFilterKey] === true) {
          filtersLength += 1;
        } else if (! isEmpty((this.tableFilters as any)[tableFilterKey]) && tableFilterKey !== 'status' && tableFilterKey !== 'planning_status' && tableFilterKey !== 'jz_users' && tableFilterKey !== 'planning_workflow' && tableFilterKey !== 'on_hold_reasons' && tableFilterKey !== 'user_filter_type') {
          filtersLength += 1;
        }
      }
    });

    return filtersLength;
  }

  protected handleToggleShowArchived(): void {
    if (this.$refs.reportDatatable) { (this.$refs.reportDatatable as ReportDatatable).refresh(); }
  }

  protected get datatableReportModel(): Report {
    return this.showArchived ? new Report().addGetParams('show_archived=true') : new Report();
  }

  protected get showObjectTypeFilter(): boolean {
    return ['bac', 'ambtelijk'].includes(this.section);
  }

  protected get showTitle(): boolean {
    return ! (
      this.hideTitle === ''
      || this.hideTitle === true
    );
  }

  @Watch('$route')
  public routeChanged(newRoute: any, oldRoute: any) {
    this.initialize();
  }
}
