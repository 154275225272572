var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-autocomplete", {
        staticClass: "with-full-border",
        attrs: {
          items: _vm.objectionTypeItems,
          "hide-details": "",
          placeholder: "Bezwaar type",
          clearable: ""
        },
        on: { change: _vm.onChange },
        model: {
          value: _vm.selectedTypes,
          callback: function($$v) {
            _vm.selectedTypes = $$v
          },
          expression: "selectedTypes"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }