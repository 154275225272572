import { ReportType } from '@/models/ReportType';
import { ReportTypes } from '@/support/ReportTypes';
import { Component, Vue, Prop } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { Report } from '@/models/Report';
import OpnameVariantFilter from '@/components/filters/opname-variant-filter/OpnameVariantFilter';
import WorkFlowFilter from '@/components/filters/work-flow-filter/WorkFlowFilter';
import { OpnameVariantEnum, OpnameVariantLabels } from '@/items/OpnameVariant';

@Component<CreateReportDialog>({})
export default class CreateReportDialog extends Vue {
  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
   */
  @Prop({ default: 'Rapport aanmaken' })
  protected title!: string;
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */
  protected reportTypes: ReportType[] = [];

  protected isLoading = false;

  protected payload: CreateReportPayload = {
    case_number: '',
    report_type: '',
    workflow: '',
    opname_variant: '',
  };

  protected opnameVariantItems: {name: string, value: string}[] = Object.values(OpnameVariantEnum).map((variant: string) => ({ name: OpnameVariantLabels[`${variant}`], value: variant }));

  // #endregion

  // #region Lifecycle Hooks / Init

  // #endregion

  // #region Class methods

  protected async mounted() {
    await this.getReportTypes();
  }

  protected close() {
    this.$emit('input', false);
    this.$emit('close');
  }
  // #endregion

  // #region Async methods
  /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
   */
  protected async getReportTypes(): Promise<void> {
    this.reportTypes = await new ReportType()
      .filter({
        is_creatable: true,
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async create() {
    if (this.isDisabled) {
      return;
    }

    this.isLoading = true;

    const objectionTypes = [
      ReportTypes.ADVIESRAPPORT_BEZWAAR,
      ReportTypes.ADVIESRAPPORT_BEZWAAR_ADDENDUM,
      ReportTypes.ADVIESRAPPORT_BEZWAAR_HOORZITTING,
      ReportTypes.ADVIESRAPPORT_BEZWAAR_AMBTELIJK,
    ];

    if (objectionTypes.some((type: string) => this.payload.report_type === type)) {
      this.payload.status = 'objection_created';
    }

    if (this.payload.report_type === ReportTypes.STUWMEERREGELING) {
      this.payload.status = 'smr_created';
    }

    const report = await new Report()
      .create(this.payload)
      .catch((error: AxiosError) => {
        this.isLoading = false;
        ErrorHandler.network(error);
      });

    this.isLoading = false;
    this.$emit('isCreated', report);
  }
  // #endregion

  // #region Getters & Setters

  protected get isDisabled(): boolean {
    return ! this.payload.case_number?.length || ! this.payload.report_type?.length || ! this.payload.workflow?.length || ! this.payload.opname_variant?.length;
  }

  protected get workflowItems() {
    return new WorkFlowFilter().items;
  }

  // #endregion

  // #region @Watchers

  // #endregion
}

interface CreateReportPayload {
  case_number: string;
  report_type: string;
  workflow: string;
  opname_variant: string;
  status?: string;
}
