var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _vm.isSimpleUser
            ? [
                _c(
                  "v-layout",
                  { attrs: { "justify-center": "", row: "", wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      {
                        staticClass: "text-sm-center",
                        attrs: { xs10: "", md8: "", lg6: "", xl6: "" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "elementPanel elementPanel--shadowed"
                          },
                          [
                            _vm.isLoading
                              ? _c("LoaderCard", {
                                  attrs: {
                                    flat: "",
                                    type: "spinner--center",
                                    minHeight: "250px"
                                  }
                                })
                              : _vm._e(),
                            !_vm.isLoading
                              ? [
                                  _vm.reports && _vm.reports.length
                                    ? _c(
                                        "v-layout",
                                        {
                                          staticClass: "item__list",
                                          attrs: { wrap: "" }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "label",
                                              attrs: { xs12: "" }
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                { attrs: { wrap: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs6: "" } },
                                                    [_vm._v("Naam")]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs4: "" } },
                                                    [_vm._v("Aangemaakt op")]
                                                  ),
                                                  _c("v-flex", {
                                                    attrs: { xs2: "" }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._l(_vm.reports, function(
                                            report,
                                            index
                                          ) {
                                            return _c(
                                              "v-flex",
                                              {
                                                key: index,
                                                staticClass: "list__item",
                                                attrs: { xs12: "" }
                                              },
                                              [
                                                _c(
                                                  "v-layout",
                                                  {
                                                    attrs: {
                                                      wrap: "",
                                                      "align-center": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs6: "" } },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            report.case_number
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs4: "" } },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "dateFormatTime"
                                                              )(
                                                                report.created_at
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "text-xs-right item__icon item__icon--action",
                                                        attrs: { xs2: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.getSSOLink(
                                                                                  report.case_number
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "remove_red_eye"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Bekijk schade"
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    : _c("v-layout", [
                                        _c("span", [
                                          _vm._v(
                                            " Op dit moment zijn er geen schade reporten, klopt dit niet? "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " Neem dan contact op met de IMG helpdesk. "
                                          )
                                        ])
                                      ])
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            : [
                _vm.showTitle
                  ? _c("v-flex", { attrs: { xs6: "", "py-0": "" } }, [
                      _c("h2", { staticClass: "elementSubTitle" }, [
                        _vm._v(_vm._s(_vm.tableName))
                      ])
                    ])
                  : _vm._e(),
                _vm.workPreparation && _vm.$store.state.isServiceOrganization
                  ? _c(
                      "v-flex",
                      { staticClass: "relative", attrs: { xs6: "" } },
                      [
                        _c(
                          "div",
                          { staticClass: "absolute elementTitleButton" },
                          [
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  color: "secondary",
                                  icon: "add_circle",
                                  round: "true",
                                  outline: "true",
                                  background: "true"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    _vm.isCreatingReport = true
                                  }
                                }
                              },
                              [_vm._v(" Voeg dossier toe ")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
                  _c(
                    "div",
                    { staticClass: "elementPanel" },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            wrap: "",
                            "justify-space-between": "",
                            "mb-3": ""
                          }
                        },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "info-icon", attrs: { shrink: "" } },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openReadMoreDialog(
                                                      "legenda_status_dots"
                                                    )
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [_vm._v("info_outline")]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [_c("span", [_vm._v("klik voor meer info")])]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            wrap: "",
                            "align-end": "",
                            "justify-space-between": "",
                            "mb-3": ""
                          }
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { shrink: "" } },
                            [
                              _c(
                                "MiButton",
                                {
                                  staticClass: "mr-1 filterButton",
                                  attrs: {
                                    outline: !_vm.isShowingFilters,
                                    small: "true",
                                    compact: "true",
                                    icon: "filter_list"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.showFilters()
                                    }
                                  }
                                },
                                [
                                  _vm._v(" Filters "),
                                  _vm.filtersLength >= 1
                                    ? _c(
                                        "div",
                                        { staticClass: "filters-amount" },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.filtersLength))
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _vm.hasFilters
                                ? _c(
                                    "MiButton",
                                    {
                                      staticClass: "ml-1 mr-2 filterButton",
                                      attrs: {
                                        color: "secondary",
                                        small: "true",
                                        compact: "true",
                                        icon: "autorenew"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.resetFilters($event)
                                        }
                                      }
                                    },
                                    [_vm._v(" Reset filters ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "custom-filter--userFilterReports",
                              attrs: { shrink: "" }
                            },
                            [
                              _c("UserFilter", {
                                ref: "userFilter",
                                attrs: {
                                  filterType: _vm.queryStatus
                                    ? _vm.queryStatus
                                    : "reports",
                                  filters: _vm.filterPayload
                                },
                                on: {
                                  selectedUserFilters: _vm.handleApplyFilter,
                                  resetFilters: _vm.resetFilters
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        {
                          staticClass: "all-filters",
                          style: "height: " + _vm.filtersHeight + "px",
                          attrs: {
                            "align-center": "",
                            row: "",
                            wrap: "",
                            "mb-3": ""
                          }
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "inner-filters",
                              attrs: { xs12: "" }
                            },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    "align-center": "",
                                    row: "",
                                    wrap: ""
                                  }
                                },
                                [
                                  _vm.showObjectTypeFilter
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "custom-filter custom-filter--autocomplete",
                                          attrs: { shrink: "" }
                                        },
                                        [
                                          _c("ReportObjectionTypeFilter", {
                                            model: {
                                              value:
                                                _vm.tableFilters.objection_type,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.tableFilters,
                                                  "objection_type",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tableFilters.objection_type"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.queryStatus || !_vm.queryStatus.length
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "custom-filter custom-filter--autocomplete",
                                          attrs: { shrink: "" }
                                        },
                                        [
                                          _c("ReportStatusFilter", {
                                            attrs: {
                                              items: _vm.reportStatusMap,
                                              canSelectAll: true
                                            },
                                            model: {
                                              value: _vm.tableFilters.statuses,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.tableFilters,
                                                  "statuses",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tableFilters.statuses"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showExpertFilter
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "custom-filter custom-filter--autocomplete",
                                          attrs: { shrink: "" }
                                        },
                                        [
                                          _c("ReportExpertFilter", {
                                            attrs: {
                                              placeholder: "Opnemer",
                                              label: "Opnemer",
                                              items: _vm.tableFilters.experts
                                            },
                                            on: {
                                              handleChange: _vm.resetFilterName
                                            },
                                            model: {
                                              value: _vm.tableFilters.experts,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.tableFilters,
                                                  "experts",
                                                  $$v
                                                )
                                              },
                                              expression: "tableFilters.experts"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.$store.state.isServiceOrganization
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "custom-filter custom-filter--autocomplete",
                                          attrs: { shrink: "" }
                                        },
                                        [
                                          _c("ReportExpertFilter", {
                                            attrs: {
                                              placeholder: "Voorcontroleur",
                                              label: "Voorcontroleur",
                                              filters: {
                                                types:
                                                  _vm.userRoles
                                                    .PreControllerRoles
                                              },
                                              items:
                                                _vm.tableFilters.pre_controller
                                            },
                                            on: {
                                              handleChange: _vm.resetFilterName
                                            },
                                            model: {
                                              value:
                                                _vm.tableFilters.pre_controller,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.tableFilters,
                                                  "pre_controller",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tableFilters.pre_controller"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.$store.state.isServiceOrganization
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "custom-filter custom-filter--autocomplete",
                                          attrs: { shrink: "" }
                                        },
                                        [
                                          _c("ReportExpertFilter", {
                                            attrs: {
                                              placeholder:
                                                "Technisch Coördinator",
                                              label: "Technisch Coördinator",
                                              filters: {
                                                types:
                                                  _vm.userRoles
                                                    .PreControllerRoles
                                              },
                                              items:
                                                _vm.tableFilters
                                                  .technical_coordinator
                                            },
                                            on: {
                                              handleChange: _vm.resetFilterName
                                            },
                                            model: {
                                              value:
                                                _vm.tableFilters
                                                  .technical_coordinator,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.tableFilters,
                                                  "technical_coordinator",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tableFilters.technical_coordinator"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showExpertFilter
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "custom-filter custom-filter--autocomplete",
                                          attrs: { shrink: "" }
                                        },
                                        [
                                          _c("ReportExpertFilter", {
                                            attrs: {
                                              placeholder:
                                                "Deskundige (indien anders dan de opnemer)",
                                              label: "Deskundige",
                                              items:
                                                _vm.tableFilters.secondExperts
                                            },
                                            on: {
                                              handleChange: _vm.resetFilterName
                                            },
                                            model: {
                                              value:
                                                _vm.tableFilters.secondExperts,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.tableFilters,
                                                  "secondExperts",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tableFilters.secondExperts"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showExpertsFirstPersonFilter
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "custom-filter custom-filter--autocomplete",
                                          attrs: { shrink: "" }
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "with-full-border",
                                            attrs: {
                                              items: _vm.firstPersonExperts,
                                              "item-text": "name",
                                              "item-value": "uuid",
                                              "hide-details": "",
                                              placeholder:
                                                "Koekie-lookie als Deskundige",
                                              "search-input":
                                                _vm.expertFirstPersonSearchInput,
                                              clearable: ""
                                            },
                                            on: {
                                              "update:searchInput": function(
                                                $event
                                              ) {
                                                _vm.expertFirstPersonSearchInput = $event
                                              },
                                              "update:search-input": function(
                                                $event
                                              ) {
                                                _vm.expertFirstPersonSearchInput = $event
                                              },
                                              keyup:
                                                _vm.searchFirstPersonDebounce,
                                              change: _vm.resetFilterName
                                            },
                                            model: {
                                              value:
                                                _vm.tableFilters.first_person,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.tableFilters,
                                                  "first_person",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tableFilters.first_person"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.$store.state.isServiceOrganization
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "custom-filter custom-filter--autocomplete",
                                          attrs: { shrink: "" }
                                        },
                                        [
                                          _c("ReportOrganizationFilter", {
                                            attrs: { canSelectAll: true },
                                            on: {
                                              handleChange: _vm.resetFilterName
                                            },
                                            model: {
                                              value:
                                                _vm.tableFilters.organizations,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.tableFilters,
                                                  "organizations",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tableFilters.organizations"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.$store.state.isServiceOrganization
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "custom-filter custom-filter--autocomplete",
                                          attrs: { shrink: "" }
                                        },
                                        [
                                          _c("ReportHandlerFilter", {
                                            attrs: { hasNoHandler: true },
                                            on: {
                                              handleChange: _vm.resetFilterName
                                            },
                                            model: {
                                              value:
                                                _vm.tableFilters
                                                  .report_handlers,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.tableFilters,
                                                  "report_handlers",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tableFilters.report_handlers"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.$store.state.isServiceOrganization
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "custom-filter custom-filter--autocomplete",
                                          attrs: { shrink: "" }
                                        },
                                        [
                                          _c("ReportJzFilter", {
                                            on: {
                                              handleChange: _vm.resetFilterName
                                            },
                                            model: {
                                              value: _vm.tableFilters.jz_users,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.tableFilters,
                                                  "jz_users",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tableFilters.jz_users"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.$store.state.isServiceOrganization
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "custom-filter custom-filter--autocomplete",
                                          attrs: { shrink: "" }
                                        },
                                        [
                                          _c("ReportMediatorFilter", {
                                            on: {
                                              handleChange: _vm.resetFilterName
                                            },
                                            model: {
                                              value: _vm.tableFilters.mediators,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.tableFilters,
                                                  "mediators",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tableFilters.mediators"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "custom-filter custom-filter--autocomplete",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("ReportTypeFilter", {
                                        attrs: { canSelectAll: true },
                                        on: {
                                          handleChange: _vm.resetFilterName
                                        },
                                        model: {
                                          value: _vm.tableFilters.types,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "types",
                                              $$v
                                            )
                                          },
                                          expression: "tableFilters.types"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "custom-filter custom-filter--autocomplete",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("ReportPlanningStatusFilter", {
                                        attrs: { canSelectAll: true },
                                        on: {
                                          handleChange: _vm.resetFilterName
                                        },
                                        model: {
                                          value:
                                            _vm.tableFilters
                                              .planning_class_status,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "planning_class_status",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tableFilters.planning_class_status"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "custom-filter custom-filter--autocomplete",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("ReportTagFilter", {
                                        attrs: {
                                          canSelectAll: true,
                                          hasNoTags: true
                                        },
                                        on: {
                                          handleChange: _vm.resetFilterName
                                        },
                                        model: {
                                          value: _vm.tableFilters.tags,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "tags",
                                              $$v
                                            )
                                          },
                                          expression: "tableFilters.tags"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "custom-filter custom-filter--autocomplete",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("ReportSkillFilter", {
                                        attrs: {
                                          canSelectAll: true,
                                          hasNoSkills: true
                                        },
                                        on: {
                                          handleChange: _vm.resetFilterName
                                        },
                                        model: {
                                          value: _vm.tableFilters.skills,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "skills",
                                              $$v
                                            )
                                          },
                                          expression: "tableFilters.skills"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "custom-filter custom-filter--autocomplete",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("ReportDepartmentFilter", {
                                        attrs: { canSelectAll: true },
                                        on: {
                                          handleChange: _vm.resetFilterName
                                        },
                                        model: {
                                          value: _vm.tableFilters.departments,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "departments",
                                              $$v
                                            )
                                          },
                                          expression: "tableFilters.departments"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "custom-filter custom-filter--autocomplete",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("ReportLevelFilter", {
                                        attrs: { canSelectAll: true },
                                        on: {
                                          handleChange: _vm.resetFilterName
                                        },
                                        model: {
                                          value: _vm.tableFilters.levels,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "levels",
                                              $$v
                                            )
                                          },
                                          expression: "tableFilters.levels"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "custom-filter custom-filter--autocomplete",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("WorkFlowFilter", {
                                        attrs: { canSelectAll: true },
                                        on: {
                                          handleChange: _vm.resetFilterName
                                        },
                                        model: {
                                          value: _vm.tableFilters.workflows,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "workflows",
                                              $$v
                                            )
                                          },
                                          expression: "tableFilters.workflows"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "custom-filter custom-filter--autocomplete",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("OpnameVariantFilter", {
                                        attrs: { canSelectAll: true },
                                        on: {
                                          handleChange: _vm.resetFilterName
                                        },
                                        model: {
                                          value:
                                            _vm.tableFilters.opname_variants,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "opname_variants",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tableFilters.opname_variants"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "custom-filter custom-filter--autocomplete",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("ManagementClassFilter", {
                                        attrs: { canSelectAll: true },
                                        on: {
                                          handleChange: _vm.resetFilterName
                                        },
                                        model: {
                                          value:
                                            _vm.tableFilters
                                              .management_info_classes,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "management_info_classes",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tableFilters.management_info_classes"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "custom-filter custom-filter--autocomplete",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("v-select", {
                                        staticClass: "with-full-border",
                                        attrs: {
                                          items: _vm.ohwFilters,
                                          "item-value": "value",
                                          "item-text": "name",
                                          "hide-details": "",
                                          placeholder: "Onderhanden Werk",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.tableFilters.ohw,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "ohw",
                                              $$v
                                            )
                                          },
                                          expression: "tableFilters.ohw"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "custom-filter custom-filter--autocomplete",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "pa-0",
                                        attrs: {
                                          label: "Verlopen deadline",
                                          color: _vm.getColor("secondary"),
                                          "hide-details": ""
                                        },
                                        on: { change: _vm.resetFilterName },
                                        model: {
                                          value: _vm.tableFilters.overdue,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "overdue",
                                              $$v
                                            )
                                          },
                                          expression: "tableFilters.overdue"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "custom-filter custom-filter--autocomplete",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "pa-0",
                                        attrs: {
                                          label: "Verlopen contract KPI",
                                          color: _vm.getColor("secondary"),
                                          "hide-details": ""
                                        },
                                        on: { change: _vm.resetFilterName },
                                        model: {
                                          value: _vm.tableFilters.overdue_kpi,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "overdue_kpi",
                                              $$v
                                            )
                                          },
                                          expression: "tableFilters.overdue_kpi"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "custom-filter custom-filter--autocomplete",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "pa-0",
                                        attrs: {
                                          label: "PRIO dossiers",
                                          color: _vm.getColor("secondary"),
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.tableFilters.is_prio,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "is_prio",
                                              $$v
                                            )
                                          },
                                          expression: "tableFilters.is_prio"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "custom-filter custom-filter--autocomplete",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "pa-0",
                                        attrs: {
                                          label: "Special dossiers",
                                          color: _vm.getColor("secondary"),
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.tableFilters.is_special,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "is_special",
                                              $$v
                                            )
                                          },
                                          expression: "tableFilters.is_special"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "custom-filter custom-filter--autocomplete",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "pa-0",
                                        attrs: {
                                          label: "Zetting / Zakking dossiers",
                                          color: _vm.getColor("secondary"),
                                          "hide-details": ""
                                        },
                                        model: {
                                          value:
                                            _vm.tableFilters.is_zetting_zakking,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "is_zetting_zakking",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tableFilters.is_zetting_zakking"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "custom-filter custom-filter--autocomplete",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "pa-0",
                                        attrs: {
                                          label: "Test dossiers",
                                          color: _vm.getColor("secondary"),
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.tableFilters.is_test,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "is_test",
                                              $$v
                                            )
                                          },
                                          expression: "tableFilters.is_test"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "custom-filter custom-filter--autocomplete",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "pa-0",
                                        attrs: {
                                          label: "Bevat EBS",
                                          color: _vm.getColor("secondary"),
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.tableFilters.has_ebs,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "has_ebs",
                                              $$v
                                            )
                                          },
                                          expression: "tableFilters.has_ebs"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "custom-filter custom-filter--autocomplete",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "pa-0",
                                        attrs: {
                                          label: "Nulmeting",
                                          color: _vm.getColor("secondary"),
                                          "hide-details": ""
                                        },
                                        model: {
                                          value:
                                            _vm.tableFilters.requires_nulmeting,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tableFilters,
                                              "requires_nulmeting",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tableFilters.requires_nulmeting"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.hasExpertArchiveVisibility
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "custom-filter custom-filter--autocomplete",
                                          attrs: { shrink: "" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "pa-0",
                                            attrs: {
                                              label: "Archief weergeven",
                                              color: _vm.getColor("secondary"),
                                              "hide-details": ""
                                            },
                                            on: {
                                              input:
                                                _vm.handleToggleShowArchived
                                            },
                                            model: {
                                              value: _vm.showArchived,
                                              callback: function($$v) {
                                                _vm.showArchived = $$v
                                              },
                                              expression: "showArchived"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.isLoadingDatatable || _vm.isLoadingUserPreferences
                        ? _c("LoaderCard", {
                            attrs: {
                              flat: "",
                              type: "spinner--center",
                              minHeight: "250px"
                            }
                          })
                        : _vm._e(),
                      !_vm.isLoadingDatatable && !_vm.isLoadingUserPreferences
                        ? _c("ReportDatatable", {
                            ref: "reportDatatable",
                            attrs: {
                              reportModel: _vm.datatableReportModel,
                              expertPlanningInclude: _vm.expertPlanningInclude,
                              isJzFilter: _vm.isJzFilter,
                              queryHeaders: _vm.queryHeaders,
                              queryStatus: _vm.queryStatus,
                              dataTableFilters: _vm.dataTableFilters,
                              tableSearchInput: _vm.tableSearchInput,
                              userPreferences: _vm.userPreferences
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm.isCreatingReport
                  ? _c(
                      "v-dialog",
                      {
                        attrs: { "max-width": "600" },
                        model: {
                          value: _vm.isCreatingReport,
                          callback: function($$v) {
                            _vm.isCreatingReport = $$v
                          },
                          expression: "isCreatingReport"
                        }
                      },
                      [
                        _c("CreateReportDialog", {
                          on: { isCreated: _vm.createdNewReport },
                          model: {
                            value: _vm.isCreatingReport,
                            callback: function($$v) {
                              _vm.isCreatingReport = $$v
                            },
                            expression: "isCreatingReport"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
        ],
        2
      ),
      _vm.isReadingMoreInfo
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isReadingMoreInfo,
                callback: function($$v) {
                  _vm.isReadingMoreInfo = $$v
                },
                expression: "isReadingMoreInfo"
              }
            },
            [
              _c("MoreInfoDialog", {
                attrs: {
                  title: _vm.activeMoreInfo.title,
                  description: _vm.activeMoreInfo.description
                },
                model: {
                  value: _vm.isReadingMoreInfo,
                  callback: function($$v) {
                    _vm.isReadingMoreInfo = $$v
                  },
                  expression: "isReadingMoreInfo"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }