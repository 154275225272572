import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import { ObjectionTypeLabels } from '@/models/Report';

@Component<ReportObjectionTypeFilter>({})
export default class ReportObjectionTypeFilter extends Vue {
  @Prop()
  protected value!: string[];

  protected objectionTypeItems: { text: string, value: string }[] = Object.entries(ObjectionTypeLabels).map(([value, key]) => ({ text: key, value }));

  protected selectedTypes: string[] = [];

  protected onChange() {
    this.$emit('change');
    this.$emit('input', this.selectedTypes);
  }

  @Watch('value')
  protected itemsChanged() {
    // clear items
    if (! this.value) {
      this.selectedTypes = [];
    } else {
      this.selectedTypes = cloneDeep(this.value);
    }
  }
}
