var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultDialog",
    {
      on: {
        close: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _vm.isLoading
        ? [
            _c(
              "template",
              { slot: "content" },
              [_c("LoaderCard", { attrs: { type: "spinner--center" } })],
              1
            )
          ]
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "template",
              { slot: "content" },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("v-text-field", {
                          attrs: { label: "Rapportnummer", "hide-details": "" },
                          model: {
                            value: _vm.payload.case_number,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "case_number", $$v)
                            },
                            expression: "payload.case_number"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("v-select", {
                          attrs: {
                            items: _vm.workflowItems,
                            "item-text": "name",
                            "item-value": "value",
                            label: "Workflow",
                            "hide-details": ""
                          },
                          model: {
                            value: _vm.payload.workflow,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "workflow", $$v)
                            },
                            expression: "payload.workflow"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("v-select", {
                          attrs: {
                            items: _vm.opnameVariantItems,
                            "item-text": "name",
                            "item-value": "value",
                            label: "Opname variant",
                            "hide-details": ""
                          },
                          model: {
                            value: _vm.payload.opname_variant,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "opname_variant", $$v)
                            },
                            expression: "payload.opname_variant"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("v-select", {
                          attrs: {
                            items: _vm.reportTypes,
                            "item-text": "name",
                            "item-value": "uuid",
                            label: "Rapporttype",
                            "hide-details": ""
                          },
                          model: {
                            value: _vm.payload.report_type,
                            callback: function($$v) {
                              _vm.$set(_vm.payload, "report_type", $$v)
                            },
                            expression: "payload.report_type"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "template",
              { slot: "actions" },
              [
                _c(
                  "v-layout",
                  { attrs: { "justify-end": "", "align-center": "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { shrink: "" } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fadedText link mr-3",
                            on: {
                              click: function($event) {
                                return _vm.close()
                              }
                            }
                          },
                          [_vm._v("Annuleren")]
                        ),
                        _c(
                          "MiButton",
                          {
                            attrs: {
                              disabled: _vm.isDisabled,
                              color: "primary",
                              small: "true"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.create($event)
                              }
                            }
                          },
                          [_vm._v(" Rapport aanmaken ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }